import React from "react"

import { StaticImage } from "gatsby-plugin-image"

import SEO from "../components/SeoComponent"

import Layout from "../components/layout"
import { useTranslation } from "react-i18next"

export default function Contact() {
  if (typeof window !== "undefined") {
    document.body.style.overflow = "visible"
  }

  const { t } = useTranslation()

  return (
    <Layout path={"contact"}>
      <SEO title={"Contact"} />
      <section className="contact">
        <div style={{ display: "grid" }} className="contact-img-container">
          <StaticImage
            src="../images/stairs.png"
            width={568}
            height={1069}
            quality={95}
            style={{ gridArea: "1/1" }}
            imgStyle={{ objectFit: "cover" }}
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Stairs"
          />
        </div>

        <div className="contact-container">
          <h1 className="contact-title h2">{t("contact.1")}</h1>
          <p className="contact-text">{t("contact.2")}</p>
          <div className="contact-form">
            <p className="contact-form-title h2">{t("contact.3")}</p>
            <form
              name="contact"
              className="form_contact"
              method="POST"
              data-netlify="true"
            >
              <input type="hidden" name="form-name" value="contact"></input>
              <label className="p-mulish alignVertical" htmlFor="name">
                {t("contact.4")}
                <input type="text" id="name" name="name" />
              </label>
              <label className="p-mulish alignVertical" htmlFor="email">
                {t("contact.5")}
                <input type="email" id="email" name="email" />
              </label>
              <label className="p-mulish alignVertical" htmlFor="question">
                {t("contact.6")}
                <input name="question"></input>
              </label>
              <input
                type="submit"
                className="form-submit"
                value={t("Submit")}
              />
            </form>
            <p className="form-telephone">Tel.: 050/700 926</p>
            <p className="form-email">Email: info@hotelfleurdelys.be</p>
          </div>
        </div>
      </section>
      <section className="location">
        <div className="location-container">
          <h1 className="location-title h2">{t("contact.7")}</h1>
          <p className="location-text">Torhoutsesteenweg 66, 8210 Zedelgem</p>
          <div className="location-placeholder">
            <iframe
              className="location-iframe"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d8420.69468081845!2d3.1643870616236387!3d51.13636057799394!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x102dbfc708b0110d!2sH%C3%B4tel%20Fleur%20De%20Lys!5e0!3m2!1snl!2sbe!4v1617376418485!5m2!1snl!2sbe"
              height="600"
              style={{ border: "0" }}
              allowfullscreen=""
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </section>
      <section className="about">
        <div className="about-container">
          <h1 className="about-title h2">{t("contact.8")}</h1>
          <p className="about-text">
            <span
              dangerouslySetInnerHTML={{
                __html: t("contact.9", {
                  interpolation: { escapeValue: false },
                }),
              }}
            ></span>
            <span
              dangerouslySetInnerHTML={{
                __html: t("contact.10", {
                  interpolation: { escapeValue: false },
                }),
              }}
            ></span>
          </p>
          <StaticImage
            src="../images/placeholder.png"
            width={928}
            height={498}
            quality={95}
            placeholder="blurred"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="The owners"
          />
        </div>
        <article className="about-quote">
          <h2 className="about-quote-title hidden">Review</h2>
          <p className="about-quote-text">{t("contact.11")}</p>
          <p className="about-quote-author">- Danielle</p>
        </article>
      </section>
    </Layout>
  )
}
